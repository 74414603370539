import styled from "styled-components";
import Col from "../../grid/Col";

export const HeroTextWrapper = styled.div`
  width: 55ch;
`

export const HeroButtonWrapper = styled(Col)`
  grid-column-gap: 1rem;
  grid-auto-flow:column;
  justify-content: start;
  align-content: start;
`

export const ButtonWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-content: start;
`

export const ToggleCardWrapper = styled.details`
  summary {
    width: 100%;
    background-color: rgba(0, 174, 239, 0.23);
    cursor: pointer;
    padding: 1.5rem;
    box-sizing: border-box;
    display: grid;
    grid-auto-flow: column;
    img{
      width: 1rem;
      height: 1rem;
      justify-self: center;
      align-self: center;
      transform: rotate(0);
      transition: 0.3s transform;
    }
  }
  
  &[open]{
    img{
      transform: rotate(180deg);
      transition: 0.3s transform;
    }
  }

  p {
    background-color: rgba(0, 174, 239, 0.23);
    padding: 1rem 1.5rem 1rem 1.5rem;
  }
`

export const ToggleButton = styled.div`
  padding: 0.5rem;
  background-color: rgba(0,23,65,0.15);
  border: 1px solid #29AAE1;
  border-radius: 7px;
  align-self: center;
  justify-self: end;
  width: 1rem;
  height: 1rem;
  display: grid;
  transition: 0.2s border;
  &:hover{
    border: 1px solid white;
  }
`

export const HeroWrapper = styled.section`
  background: var(--bg-gradient--middle);
`