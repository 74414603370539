import React from 'react';
import parse from "html-react-parser";

import {UseCaseCardContainer, UseCaseCardHeader3, UseCaseCardTextWrapper} from "../styled/cards/useCasesCardComponents";

const UseCasesCard = ({logo, title, url}) => {
    return (
        <UseCaseCardContainer href={url} target="_blank" rel="noreferrer">
            <img src={logo} alt="logo"/>
            <UseCaseCardTextWrapper>
                <UseCaseCardHeader3 color="white">
                    {parse(title)}
                </UseCaseCardHeader3>
            </UseCaseCardTextWrapper>
        </UseCaseCardContainer>
    );
};

export default UseCasesCard;