import styled from "styled-components";

export const KeyFeaturesCardWrapper = styled.div`
  display: grid;
  padding: 8vh 20px 8vh 20px;
  justify-content: stretch;
  align-items: start;
  grid-template-rows: 150px minmax(140px, max-content) auto;
  transition: background-color 0.6s;
  border-left: solid 0.5px #00afef1c;
  border-right: solid 0.5px #00afef1c;
  box-sizing: border-box;

  &:hover {
    background-color: #0d3f76;
  }

  @media screen and (max-width: 1200px) {
    padding: 20px;
    grid-template-rows: auto !important;
    border: solid 0.5px #00afef1c;
  }
`

export const KeyFeaturesCardImg = styled.img`
  height: 100px;
  margin-bottom: 40px;
  justify-self: center;
  max-width: 10vw;
  width: auto;

  @media screen and (max-width: 1200px) {
    margin-bottom: 20px;
    height: 50px;
    max-width: 100%;
  }
`

export const KeyFeaturesButtonWrapper = styled.div`
  display: grid;
  justify-content: center;
  text-align: center;
  align-self: end;

  @media screen and (max-width: 1200px) {
    margin-top: 20px;
  }
`

export const ModuleCardWrapper = styled.div`
  display: grid;
  padding: 4rem 1.25rem 4rem 1.25rem;
  justify-content: stretch;
  align-content: start;
  grid-gap: 1rem 0;
  grid-template-rows: 100px auto 1fr auto;
  transition: background-color 0.6s;
  border-left: solid 0.5px #00afef1c;
  border-right: solid 0.5px #00afef1c;
  box-sizing: border-box;

  &:hover {
    background-color: #0d3f76;
  }

  @media screen and (max-width: 1200px) {
    padding: 1.25rem;
    grid-template-rows: auto !important;
    border: solid 0.5px #00afef1c;
  }
`