import React from 'react';
import parse from "html-react-parser";

import SectionLayoutGrid from "../layouts/SectionLayoutGrid";

import Col from "../styled/grid/Col";
import {Body, Header2, Header3, Span, Title} from "../styled/typography/Typography";
import {OnlyDesktop, OnlyMobile} from "../styled/views/MobileOrDesktop";
import {ImageLogo} from "../styled/pages/products/header/HeaderComponents";
import {ButtonContainer, ButtonLink} from "../styled/button/ButtonComponents";

const HeaderSection = ({
                           title_white,
                           title_color,
                           subtitle,
                           image,
                           description,
                           button,
                           button_secondary,
                           template,
                           index
                       }) => {
    return (
        <SectionLayoutGrid id="header-section" customBackground="middle" wide={"true"} customPadding={"first"}>
            <Col span={6} center>
                {template ?
                    <>
                        <Header3><Span color="PAS-primary">{title_color}</Span></Header3>
                        <Header2 color="white" marginBottom="normal">
                            {title_white}
                        </Header2>
                    </>
                    :
                    <Title color="white" marginBottom="normal">
                        <Span color="PAS-primary">
                            {title_color}
                        </Span><br/>
                        {title_white}
                    </Title>
                }

                {subtitle && <Header2 marginBottom="small" small color="white">{parse(subtitle)}</Header2>}
                {image &&
                <OnlyMobile>
                    <Col marginBottom="medium" center>
                        <ImageLogo alt={"api-lifecycle"} src={image}/>
                    </Col>
                </OnlyMobile>
                }
                {description && <Body color="body-text">{parse(description)}</Body>}
                <ButtonContainer>
                    <ButtonLink to={button.link} color="live-primary">
                        {button.name}
                    </ButtonLink>
                    {button_secondary &&
                    <ButtonLink to={button_secondary.link} color="secondary-brand">
                        {button_secondary.name}
                    </ButtonLink>
                    }
                </ButtonContainer>
            </Col>
            {image &&
            <Col span={6} center>
                <OnlyDesktop>
                    <ImageLogo alt={title_white} src={image} index={index && "true"}
                               className={"image-for-modal"}/>
                </OnlyDesktop>
            </Col>
            }
        </SectionLayoutGrid>
    );
};

export default HeaderSection;