import React from 'react';
import parse from "html-react-parser";

import SectionLayoutGrid from "../layouts/SectionLayoutGrid";

import {Body, Header2} from "../styled/typography/Typography";
import {ButtonA} from "../styled/button/ButtonComponents";
import Col from "../styled/grid/Col";
import {ButtonWrapper} from "../styled/pages/solutions/SolutionsComponents";

const DemoSection = ({id, title, description, button, webinar}) => {
    return (
        <SectionLayoutGrid customBackground="demo" customMinHeight={"small"} id={`demo-section-${id}`}>
            <Col grid justifyContent="center">
                <Header2 color="white" textAlign="center" light={"true"}>
                    {parse(title)}
                </Header2>
                <Body color="white" textAlign="center" marginTop="small" marginBottom="small">
                    {parse(description)}
                </Body>
                <ButtonWrapper>
                    <ButtonA href={button.link} target="_blank" rel="noreferrer" color={!webinar ? "live-primary" : "PAS-primary"}>
                        {button.name}
                    </ButtonA>
                </ButtonWrapper>
            </Col>
        </SectionLayoutGrid>
    );
};

export default DemoSection;