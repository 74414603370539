import styled from "styled-components";
import Col from "../grid/Col";
import {Header3} from "../typography/Typography";

export const CardWrapper = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1rem;
  justify-content: center;
`

export const UseCaseCardContainer = styled.a`
  display: grid;
  background-color: rgba(17, 62, 108, 1);
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.25);
  transition: 0.4s background-color, 0.4s transform;
  align-items: stretch;

  &:hover {
    background-color: rgba(17, 62, 108, 0.7);
    transform: scale(1.025);
  }

  grid-template-columns: 5rem 1fr;
  padding: 1.5rem;
  box-sizing: border-box;
  grid-gap: 0 1.5rem;
  justify-items: start;
  width: calc(50% - 0.5rem);

  img {
    width: 5rem;
    height: 5rem;
    place-self: center;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const UseCaseCardTextWrapper = styled.div`
  display: grid;
  place-content: center;
`

export const UseCaseCardHeader3 = styled(Header3)`
  font-weight: 500 !important;
`