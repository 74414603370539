import React from 'react';
import {
    KeyFeaturesButtonWrapper,
    KeyFeaturesCardImg,
    ModuleCardWrapper
} from "../styled/cards/KeyFeaturesCardComponents";
import {Body, Header3} from "../styled/typography/Typography";
import {ButtonLink} from "../styled/button/ButtonComponents";

const ModuleCard = ({src, title, description, button}) => {
    return (
        <ModuleCardWrapper>
            <KeyFeaturesCardImg alt={"avatar"} src={src}/>
            <Header3 textAlign="center" color="white" marginBottom="small">{title}</Header3>
            <Body color="body-text" textAlign="center">{description}</Body>
            <KeyFeaturesButtonWrapper>
                <ButtonLink to={button.link} color="PAS-primary">{button.name}</ButtonLink>
            </KeyFeaturesButtonWrapper>
        </ModuleCardWrapper>
    );
};

export default ModuleCard;