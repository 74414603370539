import React from 'react';
import {graphql} from "gatsby";

import Layout from "../../components/layouts/Layout";
import UseCaseSection from "../../components/sections/UseCaseSection";
import ModuleSection from "../../components/sections/ModuleSection";
import DemoSection from "../../components/sections/DemoSection";
import ProductVideoSection from "../../components/pages/products/ProductVideoSection";
import RelatedContentSection from "../../components/pages/products/RelatedContentSection";
import TableSection from "../../components/sections/TableSection";
import HeaderSection from "../../components/sections/HeaderSection";

const Palp = ({data}) => {

    const {palp} = data

    return (
        <Layout lang={palp.locale} seo={palp.SEO}>
            <HeaderSection
                image={palp.hero_section.background.url}
                subtitle={palp.hero_section.subtitle}
                title_color={palp.hero_section.title_color}
                title_white={palp.hero_section.title_white}
                button={palp.hero_section.button}
                button_secondary={palp.hero_section.button_secondary}
                description={palp.hero_section.description}
            />
            <UseCaseSection
                title={palp.introduce_section.title}
                cards={palp.introduce_section.cards}
            />
            <ModuleSection
                cards={palp.module_section.cards}
                title={palp.module_section.title}
            />
            <TableSection
                title={palp.table_section.title}
                table_header={palp.table_section.table_header}
            />
            <DemoSection
                id={1}
                title={palp.demo_section.title}
                description={palp.demo_section.description}
                button={palp.demo_section.button}
            />
            <UseCaseSection
                title={palp.use_cases_section.title}
                cards={palp.use_cases_section.cards}
            />
            <ProductVideoSection
                title={palp.video_section.title}
                url={palp.video_section.youtube_url}
                cards={palp.video_section.cards}
            />
            <DemoSection
                id={2}
                title={palp.webinar_section.title}
                description={palp.webinar_section.description}
                button={palp.webinar_section.button}
            />
            <RelatedContentSection
                locale={palp.locale}
                related1_slug={palp.related_section.related1_slug}
                related2_slug={palp.related_section.related2_slug}
                related3_slug={palp.related_section.related3_slug}
            />
        </Layout>
    );
};

export const query = graphql`
   query GetSinglePALP($locale: String) {
      palp: strapiProxedoApiLifecyclePlatform(locale: {eq: $locale}) {
        demo_section {
          title
          description
          button {
            name
            link
          }
        }
        hero_section {
          title_white
          title_color
          subtitle
          id
          description
          button_secondary {
            name
            link
          }
          button {
            name
            link
          }
          background {
            url
          }
        }
        introduce_section {
          title
          cards {
            url
            title
            icon {
              url
            }
          }
        }
        locale
        module_section {
          title
          cards {
            title
            description
            button {
              name
              link
            }
            icon {
              url
            }
          }
        }
        related_section {
          related1_slug
          related2_slug
          related3_slug
        }
        table_section {
          title
          table_header {
            row_1
            row_2
            row_3
            row_4
          }
        }
        use_cases_section {
          title
          cards {
            url
            title
            icon {
              url
            }
          }
        }
        video_section {
          title
          youtube_url
          cards {
            title
            avatar {
              url
            }
          }
        }
        webinar_section {
          title
          description
          button {
            name
            link
          }
        }
        SEO {
             title
             isIndexable
             description
             keywords
             preview {
               url
             }
        }
     } 
   }
 `

export default Palp;