import React from 'react';

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";

import Col from "../../styled/grid/Col";
import {Header2} from "../../styled/typography/Typography";
import {Iframe} from "../../styled/pages/products/product-video-section/productVideoSectionComponents";
import BenefitsCard from "../../cards/BenefitsCard";

const ProductVideoSection = ({title, url, cards}) => {
    return (
        <SectionLayoutGrid id="product-video-content" customBackground="middle" wide="true">
            <Col>
                <Header2 color="white">
                    {title}
                </Header2>
            </Col>
            <Col span={7}>
                <Iframe
                    src={url}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </Col>
            <Col span={5} grid verticalcenter>
                {cards && cards.map((item) => (
                    <BenefitsCard
                        key={item.id}
                        title={item.title}
                        avatar={item.avatar.url}
                    />
                ))}
            </Col>
        </SectionLayoutGrid>
    );
};

export default ProductVideoSection;