import styled, {css} from "styled-components";

import arrow from "../../../../static/assets/table_arrow.svg"
import Col from "../grid/Col";

export const Table = styled.table`
  width: 100%;
  background: #113E6C;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.025), 0 0.625rem 1.25rem rgba(54, 69, 104, 0.05);
`

export const TableHeader = styled.thead`

`

export const TableRow = styled.tr`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;

  padding-bottom: ${props => props.padding ? "1rem" : "unset"};
`

export const TableCell = styled.td`
  display: grid;
  padding: 0.5rem 0 0.5rem 1.5rem;
  box-sizing: border-box;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
`

export const TableHeadCell = styled.th`
  display: grid;
  align-content: center;

  &:first-child {
    justify-content: start;
  }

  padding: 1rem 0 1rem 1.5rem;
  box-sizing: border-box;
`

export const TableImg = styled.img`
  place-self: center;
`

export const TableA = styled.a`
  font-family: Raleway, 'sans-serif';
  font-size: 1rem;
  font-weight: 400;
  color: var(--body-text);
  transition: color 0.3s, background-image 0.3s ease-in-out, padding-left 0.3s;

  &:hover {
    color: white;
    background-image: ${() => `url(${arrow})`};
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 1.5rem;
  }
`

/*API Landing Section*/

export const Img = styled.img`
  max-width: 100%;
  height: auto;
  width: 100%;
  place-self: center;

  ${props => props.hero && css`
    @media (min-width: 1000px) {
      transform: scale(1.3);
    }

    @media (min-width: 1700px) {
      transform: scale(1.7);
    }
  `}
`

export const MovedCol = styled(Col)`
  margin-bottom: -4rem;
`