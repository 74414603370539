import React from 'react';

import UseCasesCard from "../cards/UseCasesCard";
import SectionLayoutGrid from "../layouts/SectionLayoutGrid";

import {Header2} from "../styled/typography/Typography";
import Col from "../styled/grid/Col";
import {CardWrapper} from "../styled/cards/useCasesCardComponents";

const UseCaseSection = ({title, cards}) => {
    return (
        <SectionLayoutGrid customBackground="middle" id={`use-case-section-${title}`}>
            <Col>
                <Header2 color="white" textAlign="center">
                    {title}
                </Header2>
            </Col>
            <CardWrapper>
                {cards && cards.map((item, index) => (
                    <UseCasesCard
                        key={index}
                        title={item.title}
                        logo={item.icon.url}
                        url={item.url}
                    />
                ))}
            </CardWrapper>
        </SectionLayoutGrid>
    );
};

export default UseCaseSection;