import React from 'react';

import SectionLayoutGrid from "../layouts/SectionLayoutGrid";
import ModuleCard from "../cards/ModuleCard";

import Col from "../styled/grid/Col";
import {Header2} from "../styled/typography/Typography";
import {ColumnContainer} from "../styled/pages/products/key-features/KeyFeaturesComponents";

const ModuleSection = ({title, cards}) => {
    return (
        <SectionLayoutGrid id={"key-features"} customBackground={"middle"}>
            <Col>
                <Header2 color="white" textAlign="center">{title}</Header2>
            </Col>
            <ColumnContainer>
                {cards.map((item) => {
                    return (
                        <ModuleCard
                            key={item.id}
                            src={item.icon.url}
                            title={item.title}
                            description={item.description}
                            button={item.button}
                        />
                    );
                })}
            </ColumnContainer>
        </SectionLayoutGrid>
    );
};

export default ModuleSection;