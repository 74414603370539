import React from 'react';
import parse from "html-react-parser";

import SectionLayoutGrid from "../layouts/SectionLayoutGrid";

import Col from "../styled/grid/Col";
import {Body, Header2} from "../styled/typography/Typography";
import {
    Table,
    TableA,
    TableCell,
    TableHeadCell,
    TableHeader,
    TableImg,
    TableRow
} from "../styled/sections/sectionComponents";

import red from "../../../static/assets/Close_SM.svg"
import yellow from "../../../static/assets/yellow_table.svg"
import green from "../../../static/assets/check_table.svg"

const TableSection = ({title, table_header}) => {
    return (
        <SectionLayoutGrid id="table-section" customBackground="middle">
            <Col>
                <Header2 color="white" textAlign="center" marginBottom="small">
                    {parse(title)}
                </Header2>
            </Col>
            <Col>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHeadCell><Body color="white" bold="true" textAlign="start">{table_header.row_1}</Body></TableHeadCell>
                            <TableHeadCell><Body color="white" bold="true">{table_header.row_2}</Body></TableHeadCell>
                            <TableHeadCell><Body color="white" bold="true">{table_header.row_3}</Body></TableHeadCell>
                            <TableHeadCell><Body color="white" bold="true">{table_header.row_4}</Body></TableHeadCell>
                        </TableRow>
                    </TableHeader>
                    <tbody>
                    <TableRow>
                        <TableCell><TableA href="https://owasp.org/Top10/A01_2021-Broken_Access_Control/"
                                           target={"_blank"}
                                           rel={"noreferrer"}>Broken Access Control</TableA></TableCell>
                        <TableCell><TableImg src={red} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TableA href="https://owasp.org/Top10/A02_2021-Cryptographic_Failures/"
                                           target={"_blank"}
                                           rel={"noreferrer"}>Cryptographic Failures</TableA></TableCell>
                        <TableCell><TableImg src={yellow} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={red} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TableA href="https://owasp.org/Top10/A03_2021-Injection/" target={"_blank"}
                                           rel={"noreferrer"}>Injection</TableA></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={red} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TableA href="https://owasp.org/Top10/A04_2021-Insecure_Design/" target={"_blank"}
                                           rel={"noreferrer"}>Insecure Design</TableA></TableCell>
                        <TableCell><TableImg src={red} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={yellow} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TableA href="https://owasp.org/Top10/A05_2021-Security_Misconfiguration/"
                                           target={"_blank"}
                                           rel={"noreferrer"}>Security Misconfiguration</TableA></TableCell>
                        <TableCell><TableImg src={yellow} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={yellow} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TableA href="https://owasp.org/Top10/A06_2021-Vulnerable_and_Outdated_Components/"
                                           target={"_blank"} rel={"noreferrer"}>Vulnerable and Outdated
                            Components</TableA></TableCell>
                        <TableCell><TableImg src={red} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={yellow} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TableA
                            href="https://owasp.org/Top10/A07_2021-Identification_and_Authentication_Failures/"
                            target={"_blank"} rel={"noreferrer"}>Identification and Authentication
                            Failures</TableA></TableCell>
                        <TableCell><TableImg src={red} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TableA href="https://owasp.org/Top10/A08_2021-Software_and_Data_Integrity_Failures/"
                                           target={"_blank"} rel={"noreferrer"}>Software and Data Integrity
                            Failures</TableA></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={red} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TableA
                            href="https://owasp.org/Top10/A09_2021-Security_Logging_and_Monitoring_Failures/"
                            target={"_blank"} rel={"noreferrer"}>Security Logging and Monitoring
                            Failures</TableA></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={yellow} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                    </TableRow>
                    <TableRow padding="true">
                        <TableCell><TableA
                            href="https://owasp.org/Top10/A10_2021-Server-Side_Request_Forgery_%28SSRF%29/"
                            target={"_blank"} rel={"noreferrer"}>Server-Side Request Forgery (SSRF)</TableA></TableCell>
                        <TableCell><TableImg src={red} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={red} alt={"check"}/></TableCell>
                        <TableCell><TableImg src={green} alt={"check"}/></TableCell>
                    </TableRow>
                    </tbody>
                </Table>
            </Col>
        </SectionLayoutGrid>
    );
};

export default TableSection;