import styled from "styled-components";

export const ColumnContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  border: 10px solid #0d4179;
  //min-height: 60vh;
  grid-column: 1/-1 !important;
  box-sizing: border-box;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    border: 5px solid #0d4179;
  }
`