import React from 'react';
import {graphql, useStaticQuery} from "gatsby";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";
import ContentLibraryCard from "../../cards/ContentLibraryCard";
import {convertCategories} from "../../general/content-library/ContentLibraryFunctions";

import Col from "../../styled/grid/Col";
import {Header2} from "../../styled/typography/Typography";
import {ButtonContainer, ButtonLink} from "../../styled/button/ButtonComponents";

const RelatedContentSection = ({related1_slug, related2_slug, related3_slug, related4_slug, locale}) => {

    const data = useStaticQuery(graphql`
    {
      allStrapiContentLibraryDownloads {
        cards: nodes {
          products {
            name
            slug
          }
          filters {
            category
            lang
            theme
          }
          title
          ss_tracking_link
          slug
          date
          gated_content {
            list_token
          }
          avatar {
            url
          }
        }
      }
    }
  `)

    const {cards} = data.allStrapiContentLibraryDownloads

    const related1 = cards.find(f => f.slug === related1_slug) ?? null
    const related2 = cards.find(f => f.slug === related2_slug) ?? null
    const related3 = cards.find(f => f.slug === related3_slug) ?? null

    return (
        <SectionLayoutGrid id="related-content" customBackground="middle" wide="true">
            <Col marginTop="large">
                <Header2 color="white">
                    {locale === "hu" ? "Kapcsolódó tartalom" : "Related contents"}
                </Header2>
            </Col>
            {related1 &&
            <Col span={4}>
                <ContentLibraryCard
                    isSmall
                    title={related1.title}
                    avatar={related1.avatar.url}
                    category={convertCategories(locale, related1.filters.category)}
                    ss_link={related1.ss_tracking_link}
                    gated={related1.gated_content}
                    slug={related1.gated_content ? "/" + related1.filters.lang + "/content-library/" + related1.slug : undefined}
                />
            </Col>
            }
            {related2 &&
            <Col span={4}>
                <ContentLibraryCard
                    isSmall
                    title={related2.title}
                    avatar={related2.avatar.url}
                    category={convertCategories(locale, related2.filters.category)}
                    ss_link={related2.ss_tracking_link}
                    gated={related2.gated_content}
                    slug={related2.gated_content ? "/" + related2.filters.lang + "/content-library/" + related2.slug : undefined}
                />
            </Col>
            }
            {related3 &&
            <Col span={4}>
                <ContentLibraryCard
                    isSmall
                    title={related3.title}
                    avatar={related3.avatar.url}
                    category={convertCategories(locale, related3.filters.category)}
                    ss_link={related3.ss_tracking_link}
                    gated={related3.gated_content}
                    slug={related3.gated_content ? "/" + related3.filters.lang + "/content-library/" + related3.slug : undefined}
                />
            </Col>
            }
            <Col grid center>
                <ButtonContainer>
                    <ButtonLink to={locale === "hu" ? "/hu/content-library/" : "/en/content-library/"}
                                color="primary-brand">
                        {locale === "hu" ? "Tovább a Tudástárhoz" : "See all in Content Library"}
                    </ButtonLink>
                </ButtonContainer>
            </Col>
        </SectionLayoutGrid>
    );
};

export default RelatedContentSection;